<template>
  <div id="container" v-if="fetch_data">
    <section id="shop">
      <div class="wrap">
        <div class="cts_box mb20">
          <h2 class="main_ttl"><span><router-link :to="{ name: 'ShoppingArticles'}">ショップ</router-link></span></h2>
          <ShopMenu :fetch_data="fetch_data"/>
        </div>

        <div class="shop_item_post flex" v-bind:class="{'no-stock': fetch_data.article.status === 'status_no_stock'}">
          <h2 class="item_ttl sp" v-html="queryMarker(htmlEscape(fetch_data.article.title), query)"></h2>
          <div class="item_gallery">
            <div class="main_img"><img v-bind:src="fetch_data.article.image_files[image_index]" alt=""></div>
            <div class="sub_img flex">
              <div class="sub_img_box" v-for="(image_file, index) in fetch_data.article.image_files"
                   v-on:click="image_index = index"
                   v-bind:key="index">
                <img v-bind:src="image_file" alt="">
              </div>
            </div>
          </div>
          <div class="item_cts">
            <h2 class="item_ttl pc" v-html="queryMarker(htmlEscape(fetch_data.article.title), query)"></h2>
            <div class="comment">
              <strong style="white-space: break-spaces" v-html="queryMarker(fetch_data.article.description, query)"></strong>
              <div class="mt30" style="white-space: break-spaces;" v-html="queryMarker(htmlEscape(fetch_data.article.add_info), query)"></div>
            </div>
            <div class="price flex">
              <span>価格：</span>¥ {{ delimited(fetch_data.article.price) }}（税込）
            </div>
            <template v-if="false">
              <div class="tag" v-if="fetch_data.article.point_usable">ポイント払い可能</div>
              <div class="tag" v-else>ポイント払い不可</div>
            </template>
            <p class="point">ポイント：<span class="red">3%</span>（ポイント利用分には付与されません）</p>
            <div class="item_count flex">
              <p>数量</p>
              <select v-model="count">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
          <div class="add_cart">
            <a href="javascript:void(0)" v-on:click="addCart()" class="submit light cart">カートに入れる</a>
          </div>
        </div>

        <div class="btnBox mb50">
          <router-link :to="{ name: 'ShoppingArticles'}" class="submit light skeleton arrow back">商品一覧ページへ戻る</router-link>
        </div>

        <div class="cts_box">
          <h3 class="min_ttl">最近チェックした商品</h3>
          <div class="item_check_list flex">
            <div class="item_box flex" v-for="article in fetch_data.article_checks" v-bind:key="article.id">
              <div class="image">
                <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }"
                             @click.native="refreshIfSameRoute(article.id)">
                  <img v-bind:src="article.image_files[0]" alt="">
                </router-link>
              </div>
              <div class="detail">
                <h4>
                  <router-link :to="{ name: 'ShoppingArticle', params: {id: article.id} }">
                    {{ article.title }}
                  </router-link>
                </h4>
                <p class="price">¥ {{ delimited(article.price) }}（税込）</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <CartModal :article="fetch_data.article" v-if="add_cart" v-on:canceled="add_cart=false"/>
  </div>
</template>

<script>

import Libraries from '@/components/Libraries'
import ShopMenu from '@/views/shop/_shop_menu'
import CartModal from '@/views/shop/_cart_modal';

export default {
  mixins: [Libraries],
  components: {
    CartModal,
    ShopMenu,
  },
  data() {
    return this.initialize()
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    $route(/*to, from*/) {
      this.reset()
      this.fetchData()
    }
  },
  methods: {
    initialize() {
      return {
        fetch_data: null,
        image_index: 0,
        count: 1,
        add_cart: false,
        query: this.$route.query.query
      }
    },
    reset() {
      Object.assign(this.$data, this.initialize());
    },

    pointPercent(article) {
      return article.add_points * 100 / article.price;
    },
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}shop/article.json`, {
            params: {
              id: this.$route.params.id,
              draft: this.$route.query.draft
            }
          })
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    },
    addCart() {
      this.axios
          .post(`${this.env.api_base_url}shop/update_article.json`, {
            id: this.$route.params.id,
            count: this.count
          })
          .then(response => {
            this.fetch_data = response.data
            this.add_cart = true
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
          })
    },
    refreshIfSameRoute(article_id) {
      if (this.fetch_data.article.id === article_id) {
        this.scrollTop()
      }
    }
  }
}
</script>
